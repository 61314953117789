<template>
    <div class="btn_item add_unplan">
        <el-button size="small" type="primary" class="button_98px" v-if="!edit" @click="openAdd">录入自筹项目
        </el-button>
        <div v-if="edit" @click="openEdit">编辑</div>
        <el-dialog :title="!edit?'录入自筹项目':'编辑自筹项目'" :visible.sync="show" width="900px"
                   :close-on-click-modal="false"
                   append-to-body @closed="$refs.ruleForm.resetFields()">
            <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="130px"
                     class="parent_form add_unplan_form">
                <div class="row">
                    <div class="item long">
                        <el-form-item label="项目名称" prop="project_name">
                            <el-input v-model="ruleForm.project_name" placeholder="请输入项目名称"></el-input>
                        </el-form-item>
                    </div>
                    <div class="item long">
                        <el-form-item label="资金类型" prop="money_type">
                            <div class="radio_group">
                                <el-radio-group :value="ruleForm.money_type">
                                    <el-radio :label="1" @click.native="radioChange($event,1)">现金</el-radio>
                                    <el-radio :label="2" @click.native="radioChange($event,2)">物资</el-radio>
                                </el-radio-group>
                            </div>
                        </el-form-item>
                    </div>
                    <div class="item">
                        <el-form-item label="项目所属区域" prop="area">
                            <el-select size="medium" v-model="ruleForm.area" popper-class="global_select_popper"
                                       :disabled="zichou_project_power.area>0">
                                <el-option v-for="item in areaList" :key="item.value" :label="item.label"
                                           :value="item.value"></el-option>
                            </el-select>
                        </el-form-item>
                    </div>
                    <div class="item">
                        <el-form-item :label="`${ruleForm.money_type==2?'项目折算现金':'项目投入资金'}`"
                                      prop="project_input">
                            <el-input-number v-model="ruleForm.project_input" :step="1" :controls="false"
                                             placeholder="请输入项目投入资金"></el-input-number>
                            <div class="unit">万元</div>
                        </el-form-item>
                    </div>
                    <div class="item">
                        <el-form-item label="投入来源" prop="source">
                            <el-select size="medium" v-model="ruleForm.source" popper-class="global_select_popper">
                                <el-option v-for="item in source" :key="item.value" :label="item.label"
                                           :value="item.value"></el-option>
                            </el-select>
                        </el-form-item>
                    </div>
                    <div class="item">
                        <el-form-item label="项目类别" prop="project_type">
                            <el-select size="medium" v-model="ruleForm.project_type"
                                       popper-class="global_select_popper">
                                <el-option v-for="item in projectType" :key="item.value" :label="item.label"
                                           :value="item.value"></el-option>
                            </el-select>
                        </el-form-item>
                    </div>
                    <div class="item">
                        <el-form-item label="捐赠时间" prop="date">
                            <el-date-picker size="medium" v-model="ruleForm.date" type="month"
                                            placeholder="选择时间" value-format="yyyy-MM"
                                            style="width: 100%"></el-date-picker>
                        </el-form-item>
                    </div>
                    <div class="item">
                        <el-form-item label="受助对象" prop="object">
                            <el-input v-model="ruleForm.object" placeholder="请输入受助对象" clearable></el-input>
                        </el-form-item>
                    </div>
                    <div class="item long"
                         v-if="ruleForm.money_type==2||(ruleForm.wuzi_info&&ruleForm.wuzi_info.length)">
                        <el-form-item label="自筹具体物资">
                            <div class="wuzi_table">
                                <div class="wuzi_row" v-for="(v,k) in ruleForm.wuzi_info" :key="'wuzi_info'+k">
                                    <div class="row_item">
                                        <el-select size="medium" v-model="v.wuzi_type"
                                                   popper-class="global_select_popper"
                                                   placeholder="选择品类" clearable>
                                            <el-option v-for="item in wuziList" :key="item.value" :label="item.label"
                                                       :value="item.value"></el-option>
                                        </el-select>
                                    </div>
                                    <div class="row_item num_input">
                                        <el-input-number v-model="v.num" placeholder="输入数量"
                                                         size="medium"
                                                         :controls="false"></el-input-number>
                                        <el-select class="row_unit" size="small" v-model="v.unit"
                                                   popper-class="global_select_popper">
                                            <el-option v-for="item in unitList" :key="item.value" :label="item.label"
                                                       :value="item.value"></el-option>
                                        </el-select>
                                    </div>
                                    <div class="row_item num_input">
                                        <el-input-number v-model="v.price" placeholder="输入折算价"
                                                         :controls="false" size="medium"></el-input-number>
                                        <span class="row_unit">元</span>
                                    </div>
                                    <div class="del_row" @click="ruleForm.wuzi_info.splice(k,1)">
                                        <span class="el-icon-delete"></span>
                                    </div>
                                </div>
                                <div class="add_row">
                                    <el-button type="text" @click="ruleForm.wuzi_info.push({
                                        wuzi_type:'',
                                        num:undefined,
                                        unit:'个',
                                        price:undefined
                                    })">添加物资
                                    </el-button>
                                </div>
                            </div>
                        </el-form-item>
                    </div>
                    <div class="item long">
                        <el-form-item label="联络小组" prop="contact_group">
                            <span>{{ ruleForm.contact_group.name || '' }}</span>
                            <el-button type="text" @click="chooseDepartment" style="margin-left: 5px;">选择部门
                            </el-button>
                        </el-form-item>
                    </div>
                    <div class="item long">
                        <el-form-item label="项目概况" prop="project_des">
                            <el-input type="textarea" :rows="4" v-model="ruleForm.project_des"
                                      placeholder="请输入项目概况"></el-input>
                        </el-form-item>
                    </div>
                    <div class="item long file_box">
                        <el-form-item label="项目附件" prop="project_file_ids">
                            <fileListComp :file_list="ruleForm.project_file_ids" @change="editNameBack" />
                            <div>
                                <el-button type="text" @click="updateFile">点击上传附件</el-button>
                            </div>
                        </el-form-item>
                    </div>
                </div>
                <div class="button" v-if="zichou_project_power.power.edit">
                    <el-button class="button_88px" size="small" type="primary" @click="submitForm">保存</el-button>
                </div>
            </el-form>
        </el-dialog>
    </div>
</template>

<script>
import api from '@/api/methods/index';
import dingDisk from '@/utils/ding_disk';
import peoplePicker from '@/utils/people_picker';
import fileListComp from '@/components/file/index.vue'

export default {
    components: {fileListComp},
    props: ['edit', 'detail'],
    data() {
        return {
            show: false,
            areaList: [
                {value: 1, label: '州本级'},
                {value: 2, label: '玛沁县'},
                {value: 5, label: '甘德县'},
                {value: 6, label: '达日县'},
                {value: 4, label: '班玛县'},
                {value: 3, label: '久治县'},
                {value: 7, label: '玛多县'},
            ],
            source: [
                {value: 1, label: '财政资金'},
                {value: 2, label: '社会组织'},
                {value: 3, label: '个人'},
                {value: 4, label: '企业资金'},
            ],
            projectType: [
                {value: 1, label: '区县'},
                {value: 2, label: '街镇'},
                {value: 3, label: '村村'},
                {value: 4, label: '村企'},
                {value: 5, label: '其他'},
            ],
            ruleForm: {
                project_name: '',
                money_type: 1,
                area: '',
                project_input: undefined,
                source: '',
                project_type: '',
                project_des: '',
                contact_group: '',
                project_file_ids: [],
                date: '',
                wuzi_info: [],
                object: '',
            },
            rules: {
                project_name: [{required: true, message: '请输入项目名称', trigger: 'blur'}],
                money_type: [{required: true, message: '请选择现金类型', trigger: 'change'}],
                area: [{required: true, message: '请选择项目所属区域', trigger: 'change'}],
                source: [{required: true, message: '请选择投入来源', trigger: 'change'}],
                project_type: [{required: true, message: '请选择项目类别', trigger: 'change'}],
                contact_group: [{required: true, message: '请选择联络小组部门', trigger: 'change'}],
                project_des: [{required: true, message: '请输入项目概况', trigger: 'blur'}],
            },
            wuziList: [
                {value: '衣物', label: '衣物'},
                {value: '街镇文体用品', label: '街镇文体用品'},
                {value: '生活物资', label: '生活物资'},
                {value: '办公器材', label: '办公器材'},
                {value: '医疗物资', label: '医疗物资'},
                {value: '其它', label: '其它'},
            ],
            unitList: [
                {value: '个', label: '个'},
                {value: '件', label: '件'},
                {value: '套', label: '套'},
                {value: '箱', label: '箱'},
            ]
        }
    },
    computed: {
        zichou_project_power() {
            return this.$store.state.user.current_user.zichou_project_power
        }
    },
    methods: {
        initRuleForm() {
            this.ruleForm = {
                project_name: '',
                money_type: 1,
                area: '',
                project_input: undefined,
                source: '',
                project_type: '',
                project_des: '',
                contact_group: '',
                project_file_ids: [],
                date: '',
                wuzi_info: [],
                object: '',
            };
        },
        openAdd() {
            this.initRuleForm();
            if (this.zichou_project_power.area > 0) {
                this.ruleForm.area = this.zichou_project_power.area;
            }
            this.show = true;
        },
        openEdit() {
            this.initRuleForm();
            api.unplanned.offPlanDetail({project_off_id: this.detail.project_off_id}).then(res => {
                if (res.data.rows) {
                    if (!res.data.rows.contact_group) {
                        res.data.rows.contact_group = {}
                    }
                    if (res.data.rows.project_file_ids) {
                        res.data.rows.project_file_ids = JSON.parse(res.data.rows.project_file_ids)
                    } else {
                        res.data.rows.project_file_ids = []
                    }
                    if (res.data.rows.project_input) {
                        res.data.rows.project_input = Number(res.data.rows.project_input) / 10000;
                    } else {
                        res.data.rows.project_input = undefined;
                    }
                    if (res.data.rows.wuzi_info) {
                        let wuzi_info = [];
                        res.data.rows.wuzi_info.forEach(item => {
                            wuzi_info.push({
                                wuzi_type: item.wuzi_type || '',
                                num: item.num,
                                unit: item.unit || '个',
                                price: item.price
                            })
                        })
                        res.data.rows.wuzi_info = wuzi_info
                    } else {
                        res.data.rows.wuzi_info = []
                    }
                    if (!res.data.rows.money_type) {
                        res.data.rows.money_type = 1;
                    }
                    this.ruleForm = {...this.ruleForm, ...res.data.rows};
                    this.show = true;
                }
            })
        },
        radioChange(event, e) {
            if (event.target.tagName != 'INPUT') {
                return
            }
            if (this.ruleForm.money_type == e) {
                return;
            }
            if (e == 1 && this.ruleForm.wuzi_info && this.ruleForm.wuzi_info.length) {
                const h = this.$createElement;
                this.$msgbox({
                    title: '提示',
                    message: h('p', null, '资金类型切换为现金将清空《自筹具体物资》,是否切换?'),
                    showCancelButton: true,
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    beforeClose: (action, instance, done) => {
                        if (action === 'confirm') {
                            this.ruleForm.wuzi_info = [];
                            this.ruleForm.money_type = e;
                            done();
                        } else {
                            done();
                        }
                    }
                }).catch(() => {

                });
            } else {
                this.ruleForm.money_type = e;
            }
        },
        //选择联络小组
        chooseDepartment() {
            peoplePicker.departmentPicker([], (res) => {
                let info = {};
                info.department_id = res.departments[0].id;
                info.name = res.departments[0].name;
                this.ruleForm.contact_group = info;
            })
        },
        //上传附件
        updateFile() {
            dingDisk.dingDiskUpdate(res => {
                this.ruleForm.project_file_ids = [...this.ruleForm.project_file_ids, ...res.data];
            })
        },
        //修改名称后的附件数组
        editNameBack(file_list) {
            this.ruleForm.project_file_ids = file_list;
        },
        //预览附件
        previewFile(file_info) {
            dingDisk.previewFile(file_info)
        },
        submitForm() {
            this.$refs.ruleForm.validate((valid) => {
                if (valid) {
                    //物资验证
                    let return_bol = false;
                    let wuzi_info = [];//不为空的物资信息push进去
                    for (let i in this.ruleForm.wuzi_info) {
                        i = Number(i)
                        let item = this.ruleForm.wuzi_info[i]
                        let has_num = item.num !== undefined && item.num !== null && item.num !== '';
                        let has_price = item.price !== undefined && item.price !== null && item.price !== '';
                        if (item.wuzi_type && has_num && has_price) {//所有信息都填写了
                            wuzi_info.push(item)
                        } else {
                            if (item.wuzi_type || has_num || has_price) {//填写了任一信息
                                if (!item.wuzi_type) {
                                    this.$message({message: `请选择第${i + 1}行物资品类!`, type: 'warning'});
                                    return_bol = true
                                    break;
                                }
                                if (!has_num) {
                                    this.$message({message: `请输入第${i + 1}行物资数量!`, type: 'warning'});
                                    return_bol = true
                                    break;
                                }
                                if (!has_price) {
                                    this.$message({message: `请输入第${i + 1}行折算价!`, type: 'warning'});
                                    return_bol = true
                                    break;
                                }
                            }
                        }
                    }
                    if (return_bol) {
                        return
                    }
                    let param = {
                        project_name: this.ruleForm.project_name,
                        money_type: this.ruleForm.money_type,
                        area: this.ruleForm.area,
                        source: this.ruleForm.source,
                        project_type: this.ruleForm.project_type,
                        project_des: this.ruleForm.project_des,
                        contact_group: this.ruleForm.contact_group.department_id,
                        project_file_ids: JSON.stringify(this.ruleForm.project_file_ids),
                        date: this.ruleForm.date,
                        wuzi_info: wuzi_info.length ? JSON.stringify(wuzi_info) : '',
                        object: this.ruleForm.object,
                    }

                    console.log('param',param);
                    if (this.ruleForm.project_input) param.project_input = Number(this.ruleForm.project_input) * 10000;
                    if (this.edit) param.project_off_id = this.ruleForm.project_off_id;

                    api.unplanned.addOffPlan(param).then(res => {
                        if (res.data.state == 1) {
                            this.$message({
                                message: `${this.edit ? '编辑' : '添加'}自筹项目成功`,
                                type: 'success'
                            });
                            this.show = false;
                            if (this.edit) {
                                this.$parent.$parent.$parent.$parent.offPlanLists();
                            } else {
                                this.$parent.offPlanLists();
                            }
                        } else {
                            this.$message.error(res.data.msg);
                        }
                    })
                }
            });
        }
    }
}
</script>

<style lang="less">
.add_unplan_form {
    .button {
        border: none !important;
    }

    .radio_group {
        height: 40px;

    }

    .wuzi_table {
        width: 100%;

        .wuzi_row {
            display: flex;
            align-items: center;

            > .row_item {
                flex: 1;
                min-width: 0;
                padding-right: 10px;
                box-sizing: border-box;

                &.num_input {
                    position: relative;

                    .el-input-number {
                        input {
                            padding-right: 55px;
                        }
                    }

                    .el-select {
                        padding: 0;

                        input {
                            border: none;
                            padding: 0;
                            text-align: center;
                        }

                        i {
                            display: none;
                        }
                    }

                    .row_unit {
                        width: 50px;
                        text-align: center;
                        position: absolute;
                        right: 12px;
                    }
                }
            }

            .del_row {
                cursor: pointer;
                transition: color .2s;
                font-size: 18px;

                &:hover {
                    color: #3396fb;
                }
            }
        }
    }
}
</style>