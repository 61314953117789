<template>
    <el-dialog title="项目自筹物资统计" :visible.sync="show" width="900px"
               custom-class="wuzi_statitistic_dialog">
        <div class="table" v-loading="loading">
            <el-table :data="tableData" height="100%" v-if="!loading"
                      :summary-method="getSummaries" show-summary border>
                <el-table-column type="index" label="序号" width="130"></el-table-column>
                <el-table-column prop="wuzi_type" label="自筹物资品类"></el-table-column>
                <el-table-column label="数量">
                    <template slot-scope="scope">
                        <span>{{ scope.row.num }}{{ scope.row.unit }}</span>
                    </template>
                </el-table-column>
                <el-table-column label="折算价" prop="price">
                    <template slot-scope="scope">
                        <span>{{ scope.row.price }}元</span>
                    </template>
                </el-table-column>
            </el-table>
        </div>
    </el-dialog>
</template>

<script>
import api from '@/api/methods/index';

export default {
    props: ['area', 'date'],
    data() {
        return {
            show: false,
            tableData: [],
            loading: false,
        }
    },
    methods: {
        getSummaries(param) {
            const {columns, data} = param;
            const sums = [];
            columns.forEach((column, index) => {
                if (index === 0) {
                    sums[index] = '共计';
                    return;
                }
                const values = data.map(item => {
                    return Number(item[column.property])
                });
                if (!values.every(value => isNaN(value))) {
                    sums[index] = values.reduce((prev, curr) => {
                        const value = Number(curr);
                        if (!isNaN(value)) {
                            return Number((prev + curr).toFixed(2));
                        } else {
                            return Number(prev.toFixed(2));
                        }
                    }, 0);
                    sums[index] += '元';
                } else {
                    sums[index] = '';
                }
            });

            return sums;
        },
        getStatistics() {
            this.loading = true;
            let param = {}
            if (this.area) param.area = this.area;
            if (this.date) param.date = this.date;
            api.unplanned.Offplanstatistics(param).then(res => {
                if (res.data) {
                    this.tableData = res.data.info || []
                }
                this.loading = false
            })
        }
    },
    watch: {
        show(n, o) {
            if (n) {
                this.getStatistics()
            }
        }
    }
}
</script>

<style lang="less">
.wuzi_statitistic_dialog {
    .table {
        padding: 20px;
        height: 60vh;
        box-sizing: border-box;

        .el-table {
            tbody td div.cell {
                min-height: 36px;
            }

            tr {
                &:nth-of-type(2n) {
                    background: #f8f8f8;
                }
            }

            .el-table__footer-wrapper tbody td.el-table__cell, .el-table__header-wrapper tbody td.el-table__cell {
                background: #fff;
            }
        }
    }
}
</style>