<template>
    <div class="unplanned main">
        <ul class="tabs">
            <li>自筹项目列表</li>
        </ul>
        <div class="container">
            <div class="search_filter">
                <div class="item">
                    <div class="left">捐赠时间</div>
     
                    <el-date-picker
                        v-model="year"
                        type="year"
                        placeholder="选择年"
                        size="medium"
                        style="width:130px;margin-right: 4px;"
                        value-format="yyyy"
                        @change="search"
                    >
                    </el-date-picker>
                    <span>年</span>
                    <el-date-picker
                        v-model="month"
                        type="month"
                        placeholder="选择月"
                        size="medium"
                        style="width:130px;margin-left: 10px;margin-right: 4px;"
                        value-format="MM"
                        format="MM"
                        :disabled="!year"
                        @change="search"
                    >
                    </el-date-picker>
                    <span>月</span>
                </div>
                <div class="item">
                    <div class="left">所属地区</div>
                    <el-select size="medium" v-model="area" popper-class="global_select_popper" @change="search"
                               :disabled="zichou_project_power.area>0">
                        <el-option v-for="item in areaList" :key="item.value" :label="item.label"
                                   :value="item.value"></el-option>
                    </el-select>
                </div>
                <addUnplan :edit="false" v-if="zichou_project_power.power.edit"></addUnplan>
                <div class="btn_item">
                    <el-button size="small" type="primary" class="button_108px" @click="listsExport">自筹项目导出
                    </el-button>
                </div>
                <div class="btn_item">
                    <el-button size="small" type="primary" class="button_108px" @click="wuziStatic">自筹物资统计
                    </el-button>
                </div>
            </div>
            <div class="table">
                <el-table :data="tableData" style="width:100%" height="100%" v-loading="loading">
                    <el-table-column prop="id" label="序号" align="center"></el-table-column>
                    <el-table-column prop="project_name" label="项目名称" align="center"></el-table-column>
                    <el-table-column label="所属区域" align="center">
                        <template slot-scope="scope">
                            <span>{{ initArea(scope.row.area) }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column label="资金类型" align="center">
                        <template slot-scope="scope">
                            <span>{{ scope.row.money_type == 2 ? '物资' : '现金' }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column label="现金投入(万元)" align="center">
                        <template slot-scope="scope">
                            <span v-if="scope.row.money_type!=2">{{ scope.row.project_input?`${scope.row.project_input}万元`:'' }}</span>
                            <span v-if="scope.row.money_type==2">--</span>
                        </template>
                    </el-table-column>
                    <el-table-column label="物资投入(万元)" align="center">
                        <template slot-scope="scope">
                            <span v-if="scope.row.money_type==2">{{ scope.row.project_input?`${scope.row.project_input}万元`:'' }}</span>
                            <span v-if="scope.row.money_type!=2">--</span>
                        </template>
                    </el-table-column>
                    <el-table-column label="投入来源" align="center">
                        <template slot-scope="scope">
                            <span>{{ initSource(scope.row.source) }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column label="项目类别" align="center">
                        <template slot-scope="scope">
                            <span>{{ initProjectType(scope.row.source) }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="date" label="捐赠时间" align="center"></el-table-column>
                    <el-table-column prop="object" label="受助对象" align="center"></el-table-column>
                    <el-table-column label="联络小组" align="center">
                        <template slot-scope="scope">
                            <span>{{ scope.row.contact_group.name }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column label="操作" align="center">
                        <template slot-scope="scope">
                            <div class="operation">
                                <el-popover placement="bottom" width="100" :open-delay="200" trigger="hover"
                                            :visible-arrow="false" popper-class="operation_popover"
                                            transition="el-zoom-in-top">
                                    <ul>
                                        <li>
                                            <addUnplan :edit="true" :detail="scope.row"></addUnplan>
                                        </li>
                                        <li @click="deleteOffPlan(scope.row.project_off_id)"
                                            v-if="zichou_project_power.power.edit">删除
                                        </li>
                                    </ul>
                                    <img slot="reference" src="../../assets/img/caozuo.png" alt="">
                                </el-popover>
                            </div>
                        </template>
                    </el-table-column>
                </el-table>
                <div class="pagination">
                    <el-pagination @current-change="pageChange" :current-page="page" :page-size="pagesize"
                                   layout="total, prev, pager, next, jumper" :total="total"></el-pagination>

                    <div class="num_total">
                        <span style="margin-right: 5px">现金投入(总数): {{ num_total2 }}万元</span>
                        <br/>
                        <span>物资投入(总数): {{ num_total1 }}万元</span>
                    </div>
                </div>
            </div>
        </div>
        <wuziStatistic ref="wuziStatistic" :date="date" :area="area"></wuziStatistic>
    </div>
</template>

<script>
import api from '@/api/methods/index';
import computedData from '@/mixins/computedData.js';
import addUnplan from '@c/unplanned/add_unplan.vue'
import wuziStatistic from "@c/unplanned/wuzi_statistic.vue";

export default {
    mixins: [computedData],
    components: {addUnplan, wuziStatistic},
    data() {
        return {
            tableData: [], page: 1, pagesize: 15, total: 0, loading: false,
            areaList: [
                {value: 0, label: '全部(默认)'},
                {value: 1, label: '州本级'},
                {value: 2, label: '玛沁县'},
                {value: 5, label: '甘德县'},
                {value: 6, label: '达日县'},
                {value: 4, label: '班玛县'},
                {value: 3, label: '久治县'},
                {value: 7, label: '玛多县'},
            ],
            year:"",
            month:"",
            area: 0,
            num_total1: 0,
            num_total2: 0,
        }
    },
    computed: {
        zichou_project_power() {
            return this.$store.state.user.current_user.zichou_project_power
        }
    },
    created() {
        if (this.zichou_project_power.area > 0) {
            this.area = this.zichou_project_power.area;
        }
        this.offPlanLists();
    },
    methods: {
        offPlanLists() {
            if (!this.year) this.month = "";
            
            this.loading = true;
            this.tableData = [];
            let param = {page: this.page, pagesize: this.pagesize};
            if (this.area) param.area = this.area;
            if (this.year) param.year = this.year;
            if (this.month) param.month = this.month;
            api.unplanned.offPlanLists(param).then(res => {
                if (res.data) {
                    this.total = res.data.count || 0;
                    this.tableData = res.data.lists || [];
                    if (this.tableData.length > 0) {
                        this.tableData.forEach(item => {
                            if (item.project_input) {
                                item.project_input = Number(item.project_input) / 10000
                            }
                            if (!item.contact_group) {
                                item.contact_group = {}
                            } else if (typeof item.contact_group === 'string') {
                                item.contact_group = {
                                    name: item.contact_group
                                }
                            }
                        })
                    }
                    if (res.data.sum_money1) {
                        this.num_total1 = Number(res.data.sum_money1) / 10000 || 0
                    }
                    if (res.data.sum_money2) {
                        this.num_total2 = Number(res.data.sum_money2) / 10000 || 0
                    }
                }
                this.loading = false;

            })
        },
        search() {
            this.page = 1;
            this.offPlanLists();
        },
        pageChange(page) {
            this.page = page;
            this.offPlanLists();
        },
        deleteOffPlan(id) {
            const h = this.$createElement;
            this.$msgbox({
                title: '提示',
                message: h('p', null, '确定删除该自筹项目吗?'),
                showCancelButton: true,
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                beforeClose: (action, instance, done) => {
                    if (action === 'confirm') {
                        api.unplanned.deleteOffPlan({project_off_id: id}).then(res => {
                            if (res.data.state == 1) {
                                this.$message({
                                    message: '自筹项目删除成功',
                                    type: 'success'
                                });
                                this.offPlanLists();
                            }
                        })
                        done();
                    } else {
                        done();
                    }
                }
            }).catch(() => {

            });
        },
        listsExport() {
            let param = {
                page: 1,
                pagesize: 9999,
            };
            let _url = window.location.href;
            let __url = _url.split("#")[0];
            let url = __url + "index.php/cqh/Offplan/listsExport/?token=" + this.$store.state.user.token + `&page=${param.page}&pagesize=${param.pagesize}`;

            if (this.year) {
                param.year = this.year;
                url = url + "&year=" + param.year;
            }
            if (this.month) {
                param.month = this.month;
                url = url + "&month=" + param.month;
            }
            if (this.area) {
                param.area = this.area;
                url = url + "&area=" + param.area;
            }
            let self = this;
            api.unplanned.listsExport(param).then(res => {
                dd.biz.util.openLink({
                    url, //要打开链接的地址
                    onSuccess: function (result) {
                        console.log('打开导出地址成功');
                        self.show = false;
                    },
                    onFail: function (err) {
                        console.log('打开导出地址失败', err);
                        self.show = false;
                    }
                });
            });
        },
        wuziStatic() {
            if (this.$refs.wuziStatistic) {
                this.$refs.wuziStatistic.show = true
            }
        }
    }
}
</script>

<style scoped lang="less">
.unplanned {
    .filter {
        padding: 10px 0 0 0;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;

        .search {
            flex: 1;
            min-width: 840px;
            display: flex;
            flex-wrap: wrap;
        }
    }

    .pagination {
        justify-content: space-between !important;

        /deep/ .el-pagination {
            .el-pagination__total {
                margin-right: 50px !important;
            }

            .el-pagination__jump {
                margin-left: 50px !important;
            }
        }

        .num_total {
            flex-shrink: 0;
        }
    }
}
</style>